<template>
    <app-transition>
        <Dialog
            class="InformMessage"
            :only-text="true"
            @close="handleClose">
            <v-row text-center="">
                <v-col>
                    <div v-if="text" class="InformMessage-title">{{text}}</div>
                    <template v-if="textList.length">
                        <div v-for="(v, i) in textList"
                             class="InformMessage-title"
                             :key="i"
                        >
                            <div v-html="v"></div>
                        </div>
                    </template>
                </v-col>
            </v-row>
            <v-row v-if="showConfirmButton">
                <v-col>
                    <v-btn outlined block height="50" @click="handleConfirm">{{ $t('dialogs.ok') }}</v-btn>
                </v-col>
            </v-row>
        </Dialog>
    </app-transition>
</template>


<script>
import Dialog from "@/components/app/form/Dialog";

export default {
    name: "informDialog",
    methods: {
        handleClose() {
            this.$emit('close');
        },
        handleConfirm(){
            this.$emit('confirm');
        },
    },
    props: {
        text: {
            type: String,
            default: '',
            required: false,
        },
        textList: {
            type: Array,
            default: () => [],
            required: false,
        },
        showConfirmButton: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        Dialog,
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.InformMessage {
    &-title {
        font-size: 20px;
        font-weight: bold;
        line-height: 19px;
        margin: 8px 0;
        display: flex;
        justify-content: center;
    }
}
</style>
